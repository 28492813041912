import React from "react";
import { FaCopyright } from "react-icons/fa";

export default function Footer() {
   return (
      <div className="bg-black text-center text-white p-3 py-4">
         <span>
            Powered by <span className="grad-text font-extrabold">OLLIE</span> All Copyright
            reserved. © 2024{" "}
         </span>
      </div>
   );
}
