import React from "react";
import { FaBars, FaTelegram, FaTwitter } from "react-icons/fa";

export default function Header() {
   const toggleMenu = () => {
      document.querySelector(".menu-link").classList.toggle("active-link");
   };
   return (
      <div className="header shadow-sm py-3">
         <div className="container">
            <div className="row align-items-center">
               <div className="col flex items-center">
                  <div className="logo flex items-center text-white font-bold text-3xl gap-2 text-fierce">
                     <img
                        src="/asset/ollie.png"
                        alt="Yukan"
                        className="img-fluid"
                        width={50}
                     />{" "}
                     <span>OLLIE</span>
                  </div>
               </div>
               <div className="col">
                  <div className="menu-link d-lg-flex justify-content-evenly fw-bold text-white">
                     <a href="#" onClick={toggleMenu}>
                        Home
                     </a>
                     <a href="#about" onClick={toggleMenu}>
                        About
                     </a>
                     <a href="#tokenomics" onClick={toggleMenu}>
                        Tokenomics
                     </a>
                     <a href="#roadmap" onClick={toggleMenu}>
                        Roadmap
                     </a>
                     {/* <a href="#" onClick={toggleMenu}>Home</a> */}
                  </div>
               </div>
               <div className="col btn-con">
                  <div className="socials flex items-center justify-end text-white  gap-3">
                     <a href="https://t.me/OllieToken" target={"_blank"} className="text-3xl bg-grad fs-3 p-2">
                        <FaTelegram />
                     </a>
                     <a href="https://twitter.com/ollietoken_" target={"_blank"} className="text-3xl bg-grad fs-3 p-2">
                        <FaTwitter />
                     </a>
                  </div>
                  {/* <div className=" d-flex justify-content-end">
                        <a href="#" className='btn btn-sm font-bold px-4 py-2 rounded-pill'> Buy Now</a>
                    </div> */}
               </div>
               <div className="col menu-bar">
                  <div
                     className="menu-bar flex justify-end text-right"
                     onClick={toggleMenu}
                  >
                     <i className="fw-bold fs-1 flex justify-end">
                        <FaBars />
                     </i>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
