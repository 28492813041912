import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HTB from "../components/HTB";
import Imager from "../components/Image";
import Intro from "../components/Intro";
import Roadmap from "../components/Roadmap";
import Tokenomics from "../components/Tokenomics";

export default function Home() {
   return (
      <div className="font-bold">
         <Header />
         <Intro />
         <Tokenomics />
         <Imager />
         <HTB />
         <Footer />
      </div>
   );
}
