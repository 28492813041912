import React from "react";
import { FaShieldAlt, FaTv, FaWallet } from "react-icons/fa";
import Roadmap from "./Roadmap";
import Socials from "./Socials";

export default function HTB() {
   return (
      <div className="wrap intro py-16">
         <div className="container">
            <div className="grid gap-4 items-center lg:grid-cols-2">
               <div className="text-wrap">
                  <div className="heading ls text-fierce text-2xl lg:text-4xl font-extrabold text-fierce text-white uppercase">
                     <span>How to buy $ollie</span>
                  </div>
                  <div className="text-lg col-lg-8 py-3 text-neutral-300 ">
                     <span>
                        Here's a guide on how to buy ollie
                        <span className="grad-text"> $OLLIE</span>
                     </span>
                  </div>
                  <div className="btn-wrap text-fierce ls">
                     <button className="btn ls rounded-pill text-white p-3 w-full lg:w-[50%] bg-grad">
                        BUY $OLLIE
                     </button>
                  </div>
               </div>
               <div className="steps">
                  <div className="grid gap-5 p-3 ">
                     <div className="wrap  shadow flex gap-5 items-center p-3">
                        <div className="">
                           <div className="heading ls text-fierce text-Xl flex items-center mb-3 gap-2 text-white font-semibold">
                              <span className="bg-grad p-2 px-3 rounded-full flex items-center justify-center">
                                 {" "}
                                 1
                              </span>
                              <span> Create a Phantom wallet</span>
                           </div>
                           <div className="text px-2">
                              Download Phantom wallet from the app store or
                              Google play store.
                           </div>
                        </div>

                        <div className="img-wrap">
                           {/* <img
                              src="/asset/ollie.png"
                              alt="$OLLIE"
                              className="w-[150px]"
                           /> */}
                           <FaWallet fontSize={60} />
                        </div>
                     </div>

                     <div className="wrap  shadow flex gap-5 items-center p-3">
                        <div className="">
                           <div className="heading ls text-fierce text-Xl flex items-center mb-3 gap-2 text-white font-semibold">
                              <div className="w">
                                 <span className="bg-grad p-2 px-3 rounded-full flex items-center justify-center">
                                    {" "}
                                    2
                                 </span>
                              </div>
                              <span>Get some SOL</span>
                           </div>
                           <div className="text px-2">
                              Buy some SOL from an exchange (e.g binance) and send to your wallet.
                           </div>
                        </div>

                        <div className="img-wrap">
                           {/* <img
                              src="/asset/ollie.png"
                              alt="$OLLIE"
                              className="w-[150px]"
                           /> */}
                           <FaShieldAlt fontSize={60} />
                        </div>
                     </div>

                     <div className="wrap shadow flex gap-5 items-center p-3">
                        <div className="">
                           <div className="heading ls text-fierce text-Xl flex items-center mb-3 gap-2 text-white font-semibold">
                              <span className="bg-grad p-2 px-3 rounded-full flex items-center justify-center">
                                 {" "}
                                 3
                              </span>
                              <span> Go to Raydium</span>
                           </div>
                           <div className="text px-2">
                              Connect to{" "}
                              <a href="#" className="grad-text">
                                 https://raydium.io/swap/
                              </a>{" "}
                              and swap your SOL for OLLIE
                           </div>
                        </div>

                        <div className="img-wrap">
                           {/* <img
                              src="/asset/ollie.png"
                              alt="$OLLIE"
                              className="w-[150px]"
                           /> */}
                           <FaTv fontSize={60} />
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <Roadmap />

            <Socials />
         </div>
      </div>
   );
}
