import React from "react";

export default function Roadmap() {
   const roadmapArr = [
      "LAUNCH",
      "COMMUNITY BUILDING",
      "VIBE AND HOLD",
      "THE MOON",
   ];
   return (
      <div className="wrap mt-10">
         <div className="heading flex flex-wrap justify-between items-center">
            <span className="lg:text-5xl text-3xl py-4 text-white font-bold text-fierce">
               Roadmap
            </span>
         </div>

         <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 text-cente">
            {roadmapArr.map((i, index) => (
               <div className="wrap p-4 bg-black text-white shadow-lg" key={index}>
                  <div className="heading text-fierce ls">Phase {index+1}</div>
                  <div className="text-wring font-extrabold text-2xl">
                     <span>{i}</span>
                  </div>
               </div>
            ))}
         </div>
      </div>
   );
}
