import React from "react";
import { FaChartLine, FaClipboard } from "react-icons/fa";
import { copyToClipboard } from "../utils/copy";

export default function Intro() {
   return (
      <div className="intro py-16">
         <div className="container">
            <div className="grid min-h-[80dvh] grid-cols-1 lg:grid-cols-2 items-center">
               <div className="text-wrap">
                  <div className="text-heading text-fierce text-white text-5xl lg:text-7xl font-extrabold">
                     <span>$OLLIE</span>
                  </div>

                  <div className="abt-wrap text-gray-200 text-sm py-3">
                     <span>
                        Get ready for a wild ride with Ollie the Ostrich! Our
                        feathery friend. Ollie is the heart and soul of a new
                        meme coin revolution.
                     </span>
                  </div>

                  <div className="ca-wrap">
                     <span className="grad-text font-sans font-bold">
                        $OLLIE Contract Address
                     </span>
                     <div className="flex">
                        <div className="wrap flex gap-5 justify-between items-center px-3 py-2 rounded-lg  grad-border bg-white bg-opacity-10 text-white">
                           <span className="max-w-[100px] overflow-hidden text-ellipsis">Coming soon</span>
                           <span onClick={() => copyToClipboard("COming soon")} className="cursor-pointer">
                              <FaClipboard />
                           </span>
                        </div>
                     </div>
                  </div>

                  <div className="btn-wrap flex gap-3 mt-5">
                     <button className="btn rounded-pill text-white p-3 w-full lg:w-[50%] bg-grad">
                        {" "}
                        BUY $OLLIE
                     </button>
                     <button className="btn rounded-pill p-3 bg-white text-xl bg-opacity-50 text-white">
                        <FaChartLine />
                     </button>
                  </div>
               </div>
               <div className="img-wrap flex lg:justify-center">
                  <img src="/asset/ollie.png" alt="OLLIE" />
               </div>
            </div>
         </div>
      </div>
   );
}
