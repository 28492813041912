import React from "react";
import { FaBan, FaFire, FaHandshake, FaRecycle, FaUsers, FaWater } from "react-icons/fa";

export default function Tokenomics() {
   const cardFunc = (icon, value, name) => {
      return { icon, value, name };
   };

   const cardArr = [
    cardFunc(<FaWater />,"60%", "initial liquidity" ),
    cardFunc(<FaBan />,"0/0 ", "TAX" ),
    cardFunc(<FaUsers />,"10%", "Team (vested)" ),
    cardFunc(<FaHandshake />,"20%", "community incentives." ),
    cardFunc(<FaRecycle />,"10%", "Business Development & partnerships." ),
   ]
   return (
      <div className="wrap bg-sec py-16">
         <div className="container">
            <div className="heading flex flex-wrap justify-between items-center">
               <span className="lg:text-5xl text-3xl py-4 text-white font-bold text-fierce">
                  Tokenomics
               </span>
               <div className="text-wrap bg-black p-2 grad-border bg-opacity-10">
                  <span className="text-gray-300">
                     Total Supply:{" "}
                     <span className="grad-text"> 2 Billion $OLLIE</span>
                  </span>
               </div>
            </div>{" "}
            <br />
            <div className="card-wrap">
               <div className="grid grid-cols-2 gap-5 lg:grid-cols-4">
                  {cardArr.map((i, index) => (
                     <div
                        className="card bg-neutral-900 border-none rounded-lg text-center"
                        key={index}
                     >
                        <div className=" h-full backdrop-blur-3xl py-4 rounded-2xl">
                           <div className="card-icon flex justify-center text-2xl text-white text-fierce">
                             {i.icon}
                           </div>
                           <div className="card-value text-3xl font-semibold mt-2 text-fierce text-sec">
                              <span>{i.value}</span>
                           </div>
                           <div className="card-name text-neutral-300 capitalize">
                              <span>{i.name}</span>
                           </div>
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </div>
      </div>
   );
}
