import React from "react";
import { FaTelegram, FaTwitter } from "react-icons/fa";

export default function Socials() {
   return (
      <div className="wrap my-10 bg-black py-10">
         <div className="heading font-bold text-4xl text-white text-center ls text-fierce">
            <span>JOIN OUR COMMUNITY</span>
         </div>
         <div className="flex items-center justify-center gap-5 mt-5 text-white">
            <a
               href="https://t.me/OllieToken"
               target={"_blank"}
               className="text-6xl rounded-lg bg-grad fs-3 p-2"
            >
               <FaTelegram />
            </a>
            <a
               href="https://x.com/olliecoinsei"
               target={"_blank"}
               className="text-6xl rounded-lg bg-grad fs-3 p-2"
            >
               <FaTwitter />
            </a>
         </div>
      </div>
   );
}
